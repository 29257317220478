<template>
  <!-- BIO BODY -->
  <scroll-container>
    <Container v-if="companyExists">
      <!-- <SpinContainer :loading="loading"> -->
      <div class="flex flex-col lg:flex-row gap-4 min-h-screen">
        <!-- Company contact details section -->
        <div class="w-full lg:w-4/12 px-4 py-8 flex flex-col items-start justify-start">
          <h1 class="text-base font-bold w-full text-center mb-5 block md:hidden">
            {{ companyData.companyName }}
          </h1>
          <div class="flex flex-col items-start">
            <Skeleton class="block mb-5" width="100%" height="10rem" v-if="loading" />
            <img
              v-if="!loading && companyData.companyLogo"
              class="pb-5 md:pb-8 w-full md:max-w-[350px] md:max-h-[200px]"
              :src="companyData.companyLogo"
              alt="Company Logo"
            />
            <div
              v-else-if="!loading && !companyData.companyLogo"
              class="h-[160px] w-[160px] px-4 py-8 mb-6 bg-pitch-black flex items-center justify-center"
            >
              <h2 class="!text-white !font-light">{{ companyData.companyName }}</h2>
            </div>
            <div class="flex flex-col items-start ml-4 md:ml-0">
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <p v-if="!loading && companyData.companyPhone" class="mb-2 text-sm md:text-base">
                <font-awesome-icon class="mr-2" :icon="['far', 'globe-pointer']" />
                {{ $f.truncate(companyData.companyPhone, 40) }}
                <font-awesome-icon
                  class="ml-1 text-cool-gray-500 cursor-pointer hover:text-cool-gray-800 transition-all"
                  :icon="['far', 'copy']"
                  v-tooltip="'Click to copy phone number'"
                  @click.native="() => store.dispatch('copyToClipboard', companyData.companyPhone)"
                />
              </p>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <p v-if="!loading && companyData.companyWebsite" class="mb-2 text-sm md:text-base">
                <font-awesome-icon class="mr-2" :icon="['far', 'phone']" />
                {{ $f.truncate(companyData.companyWebsite, 40) }}
                <font-awesome-icon
                  class="ml-1 text-cool-gray-500 cursor-pointer hover:text-cool-gray-800 transition-all"
                  :icon="['far', 'copy']"
                  v-tooltip="'Click to copy website url'"
                  @click.native="
                    () => store.dispatch('copyToClipboard', companyData.companyWebsite)
                  "
                />
              </p>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <p v-if="!loading && companyData.companyEmail" class="mb-2 text-sm md:text-base">
                <font-awesome-icon class="mr-2" :icon="['far', 'envelope']" />
                {{ $f.truncate(companyData.companyEmail, 40) }}
                <font-awesome-icon
                  @click.native="() => store.dispatch('copyToClipboard', companyData.companyEmail)"
                  class="ml-1 text-cool-gray-500 cursor-pointer hover:text-cool-gray-800 transition-all"
                  :icon="['far', 'copy']"
                  v-tooltip="'Click to copy email'"
                />
              </p>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <p v-if="!loading && formattedCompanyAddress" class="text-sm md:text-base">
                <font-awesome-icon class="mr-2" :icon="['far', 'location-dot']" />
                &nbsp;&nbsp;{{ $f.truncate(formattedCompanyAddress, 40) }}
                <font-awesome-icon
                  class="ml-1 text-cool-gray-500 cursor-pointer hover:text-cool-gray-800 transition-all"
                  :icon="['far', 'copy']"
                  @click.native="() => store.dispatch('copyToClipboard', formattedCompanyAddress)"
                />
              </p>
              <div
                v-if="!loading && companyData && companyData.companySocial"
                class="flex w-full gap-5 justify-start mt-4 md:mt-8"
              >
                <span v-for="social in Object.keys(companyData.companySocial)" :key="social">
                  <a
                    v-if="companyData.companySocial[social]"
                    class="cursor-pointer hover:text-cool-gray-800 transition"
                    :href="companyData.companySocial[social]"
                  >
                    <font-awesome-icon class="text-xl md:text-2xl" :icon="['fab', social]" />
                  </a>
                </span>
              </div>
              <div class="flex gap-4 mt-4" v-else-if="loading">
                <Skeleton class="block" width="2rem" height="2rem" />
                <Skeleton class="block" width="2rem" height="2rem" />
                <Skeleton class="block" width="2rem" height="2rem" />
                <Skeleton class="block" width="2rem" height="2rem" />
              </div>
            </div>
          </div>
        </div>

        <!-- Company Bio section -->
        <div
          class="w-full lg:w-8/12 px-4 py-8 flex flex-col justify-start border-t border-cool-gray-200"
        >
          <Skeleton class="block mb-5" width="100%" height="3rem" v-if="loading" />
          <h1 v-if="!loading" class="text-lg font-bold mb-8 hidden md:block">
            {{ companyData.companyName }}
          </h1>

          <TabView class="company-bio--tabs">
            <TabPanel :header="l('About us')">
              <Skeleton class="block mb-5" width="100%" height="10rem" v-if="loading" />
              <p
                v-if="!loading && companyData.companyBio"
                class="text-cool-gray-700"
                v-html="companyData.companyBio"
              ></p>
              <div v-if="loading" class="flex">
                <div class="flex flex-col pr-5">
                  <Skeleton class="block mb-5" width="4rem" height="4rem" v-if="loading" />
                  <Skeleton class="block mb-5" width="4rem" height="4rem" v-if="loading" />
                  <Skeleton class="block mb-5" width="4rem" height="4rem" v-if="loading" />
                </div>
                <Skeleton class="block mb-5" width="100%" height="20rem" v-if="loading" />
              </div>
              <Galleria
                v-if="!loading"
                class="mb-6 md:mb-10"
                :images="images"
                :responsiveOptions="responsiveOptions"
              />
            </TabPanel>
            <TabPanel :header="l('Get a quote')">
              <h2 class="mb-4 w-full">
                {{
                  l('To get started creating an estimate, please select the options provided below')
                }}
              </h2>
              <p class="mb-12 w-full">
                {{
                  l(
                    `Simply choose one of the options below that best match your needs, and we'll instantly generate a shoppable estimate. You'll be redirected to the estimate, where you can make upgrades, refine your selections, and submit a request for proposal to the contractor with just one click.`
                  )
                }}
              </p>
              <OpenQuotes :companyId="companyId" :leadFormId="companyData.leadRotationId" />
            </TabPanel>
            <TabPanel :header="l('Company rating')">
              <grid
                class="flex px-0! pt-2"
                ref="grid"
                type="contractorRating"
                :camelCase="true"
                :rightPadding="false"
                title="Ratings"
                :isMain="false"
                :filters="{ companyId }"
                :createNew="false"
                :showToolbar="false"
                :order="[['timeCreated', 'desc']]"
                :visible="['creatorId', 'overallRating', 'description']"
              >
                <template #filterPresetBefore>
                  <RatingSummary :creatorId="null" :ratingData="companyData.contractorRatingData" />
                </template>
              </grid>
            </TabPanel>
          </TabView>

          <div v-if="form" class="mt-6 p-12 border border-cool-gray-200 rounded-md">
            <!-- FORM HAS BEEN SUBMITTED -->
            <div
              class="success-container flex flex-col justify-center items-center w-full h-full"
              v-if="submitted"
            >
              <font-awesome-icon
                icon="circle-check"
                class="check !h-28 !w-28 text-matcha-500 mb-12"
              />
              <h1 class="mb-4">{{ l('Submission Successful') }}</h1>
              <div class="text-lg">
                {{
                  l('Thank you for your submission! We will get back to you as soon as possible')
                }}.
              </div>
            </div>

            <!-- FORM IS LOADING -->
            <div v-else-if="submitting" class="flex items-center w-full h-full">
              <spinner class="mx-auto" :loading="1" size="2em" />
            </div>

            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-4" v-else-if="form">
              <div class="flex flex-col p-4">
                <h2 class="mb-4">Got a question?</h2>
                <p>
                  Filling out the contact form below. One of our agents will get back to you as soon
                  as possible.
                </p>
              </div>
              <div>
                <LeadFormContent
                  :aoFields="form ? form.aoFields : []"
                  :aoFieldOptions="form ? form.aoFieldOptions : []"
                  :formFields="defaultFormFields"
                  :oForm="form ? form.oForm : {}"
                  @submit="(payload) => onSubmitLeadForm(form.lead_rotation_id, null, payload)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </SpinContainer> -->
    </Container>

    <!-- COMPANY NOT FOUND -->
    <div v-else class="error-message h-screen w-screen flex flex-col justify-center items-center">
      <font-awesome-icon class="!h-36 !w-36 mb-8" :icon="['fal', 'face-thinking']" />
      <h1 class="mb-3">{{ l('Could not find company') }}</h1>
      <p>
        {{
          l(
            'This company does not exist or is not publicly available. Please double check the url'
          )
        }}.
      </p>
    </div>
  </scroll-container>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useTranslation from '@/components/composables/Translation'
import useForm from '@/components/forms/Form'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import RatingSummary from '@/components/ratings/RatingSummary.vue'
import Grid from '@/components/ui/Grid/Grid.vue'
import OpenQuotes from '@/components/openQuote/OpenQuotes.vue'
import LeadFormContent from '@/components/leads/LeadFormContent.vue'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import Skeleton from 'primevue/skeleton'

const route = useRouter()
const store = useStore()
const { form, fetchLeadForm, defaultFormFields, onSubmitLeadForm, submitting, submitted } =
  useForm()
const { l, setLocalization } = useTranslation()
const companyExists = ref(true)

const companyId = computed(() => route.currentRoute.value.params.companyId)
const companyData = ref({})
const loading = ref(true)
const formattedCompanyAddress = computed(() => {
  const companyAddress = companyData.value?.companyAddress
  const companyCity = companyData.value?.companyCity

  if (!companyAddress) {
    return null
  }

  return companyCity ? `${companyAddress}, ${companyCity}` : companyAddress
})

const responsiveOptions = ref([
  {
    breakpoint: '1300px',
    numVisible: 4
  },
  {
    breakpoint: '575px',
    numVisible: 1
  }
])

const images = computed(
  () =>
    companyData.value.presentation_file_ids &&
    companyData.value.presentation_file_ids.map((fileId) => {
      return `${import.meta.env.VITE_BASE_FILE_URL}file/view/${fileId}`
    })
)

const fetchCompanyData = async () => {
  try {
    let payload
    const response = await store.dispatch('ajax', {
      path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/companyBio`,
      data: { company_id: companyId.value }
    })
    payload = response.payload

    if (!payload || !payload.company_name) {
      companyExists.value = false
    } else {
      let data = {
        companyName: payload.company_name,
        companyBio: payload.company_bio,
        companyAddress: payload.company_address,
        companyCity: payload.company_city,
        companyProv: payload.company_prov,
        companyPostal: payload.company_postal,
        companyWebsite: payload.company_website,
        companyEmail: payload.company_email,
        companyPhone: payload.company_phone,
        companyTimeCreated: payload.company_time_created,
        companySocial: payload.social,
        leadRotationId: payload.lead_rotation_id,
        companyIsRatingsPublic: payload.company_is_ratings_public,
        presentation_file_ids: payload.presentation_file_ids,
        contractorRatingData: {
          byOthers: payload.contractor_ratings.items
        }
      }
      setLocalization(payload.localizationLanguageCode, payload.localizationCountryCode)

      if (payload.company_logo_file_id) {
        data = {
          ...data,
          companyLogo: `${import.meta.env.VITE_BASE_FILE_URL}file/view/${payload.company_logo_file_id}`
        }
      }

      companyData.value = data
    }
  } catch (e) {
    companyExists.value = false
  }

  loading.value = false
}

onMounted(async () => {
  await fetchCompanyData()
  if (companyData.value.leadRotationId) fetchLeadForm(companyData.value.leadRotationId)
})
</script>
